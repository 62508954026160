<template>
  <v-app>
    <v-container fluid full-height style="margin-top: 35px;">
      <v-form class="imgup" name="imgup" @submit.prevent="save_test">
        <v-row min-height>
          <v-col cols="12" align="center">
            <br />
            <p v-html="tdata['header']">
            
            </p>
            <br />
            <p v-html="tdata['description']">
            
          </p>
          </v-col>
        </v-row>

        <!-- <v-progress-linear 
        color="orange" 
        value="25" 
        height="30">
      </v-progress-linear>
      <span class="progress-bar-txt">
        0 / 0 пачок
      </span>
         -->
      </v-form>
    </v-container>
  </v-app>
</template>
<style scoped>
.correct{
  background-color: #3CB371;
}
.wrong{
  background-color: #b22222a3;
}

.v-progress-linear{
  border-radius: 12px;
  margin: 0 ;
}
.progress-bar-txt{
  position: relative;
    font-size: 14px;
    top: -24px;
    left: 6px;
    z-index: 1;
    color:#5e7f9d;
    width: 100%;
    display: block;
    text-align: center;
}
</style>

<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
export default {
  data() {
    return {
      url: null,
      answer: null,
      answers: [],
      file: "",
      task: false,
      task_id: null,
      descr: "",
      header: "",
      tdata: [],
      answered:false

    };
  },
  mounted() {
    
  },
  methods: {
    
    save_test: function () {

      console.log(this.answer);
      console.log(this.answers.length);
      console.log(this.answer);
      console.log(this.answers);
      if(this.answer==null&&this.answers.length==0)
      {
         this.$emit('pushe', {header:'Повідомлення',text:'<font style="font-size: 19px;">Обери хоча б одну відповідь</font>'});
        return;
      }
      
      axios
        .post(API_LOCATION,
        { data: "component_data", action: "save_test", task: this.task_id, 'answer':this.answer, 'answers':this.answers },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          console.log("SUCCESS!!");
          if (resp.data == "OK") {

            this.answered=true;
            //this.$router.push({ name: "Tasks" });
            // this.$router.push('/')
          }
        });
    },
  },
  computed: {
   
    title() {
        return this.$t('title')
      }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");

    console.log(this.$route.params.routeparam);
    if (this.$route.params.routeparam) {
      this.task_id = this.$route.params.routeparam;
      this.task = true;
      axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "load_task", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          this.tdata = JSON.parse(resp.data.data_json);
          this.descr = this.tdata.description_ru;
          this.header = this.tdata.header_ru;
          const ex = resp.data.id_ex
          const moderate=resp.data.moderate
          const correct=resp.data.correct

          if(ex!=null)
          {
            this.task = false;
            this.$router.push({ name: "Tasks" });
            if(moderate==1)
            {
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання знаходиться на модерації.'});
            }
            else
            {
              
              var task_state=correct==1?'Успішно':'Помилка';
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання вже виконано.<br>Статус: <b>'+task_state+'</b>'});
            }
          }
          //console.log(this.tdata);
        });
    } else {
      this.task = false;
      this.$router.push({ name: "Main" });
    }
  },
};
</script>

<i18n>
{
  
  "ua":{
      "title":"Збільшення продажів",
      "btn_save":"Зберегти",
      "btn_close":"Закрити"
      

  }
}
</i18n>